//searches.js..make_map exported as App.run() see bottom of file
function make_map() {
    var mymap = L.map('map1');//.setView([56, -3], 8.8);
    L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
        maxZoom: 18,
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
            '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
            'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: 'mapbox.streets'
    }).addTo(mymap);
    /* 
    //renderer option so circleMarker's go on canvas- svs crcl bng a dom objct
    //use canvas to render circleMarkers
    var myrenderer = L.canvas({
        padding: 0.5
    });
    var len = Translation.p_list.length;
    var i; //because strict mode is on for js
    for (i = 0; i < len; i++) {
        //get js dynamic variable name
        var dynamic = i;
        var dynamic = getLatLng(Translation.p_list[i][2],Translation.p_list[i][3]);
        console.log(dynamic);
        var mycircleMarker = L.circleMarker(dynamic,{
            renderer: myrenderer,
            color: '#3388ff',
            radius: 4
        }).addTo(mymap);

    }//for loop end
    //end of canvas circlemarker

*/
    

        var pointList = getPoints();
       var polyline = new L.Polyline(pointList, {
        color: 'red',
        weight: 3,
        opacity: 0.6,
        smoothFactor: 1
    });
    //firstpolyline.addTo(mymap);
    //mymap.addLayer(polyline);
    polyline.addTo(mymap);
    drawCircleMarkers(L,mymap);
    
    //pickup & dropoff markers
    var m1 = L.marker(getLatLng(Translation.pickup[2],Translation.pickup[3])).addTo(mymap);
    var m2 = L.marker(getLatLng(Translation.dropoff[2],Translation.dropoff[3])).addTo(mymap);
    //make a featureGroup and add polyline,m1,m2 for setting bounds
    var allPoints = L.featureGroup([polyline, m1, m2]);
    mymap.fitBounds(allPoints.getBounds(),{ padding: [20, 20]});
    //console.log(Translation.p_list);
    /*
    var circle = L.circle([55.9, -3.3], {
      color: 'red',
      fillColor: '#f03',
      fillOpacity: 0.3,
      radius: 30000
    }).addTo(mymap); 
    */
    //a pop
    //marker1.bindPopup("<b>Start</b><br>pickup point").openPopup();
    //end of make_map
    //make_map end

    console.log('make_map');
} //make_map end
//#################################################################################

function drawCircleMarkers(L,mymap){
    var myrenderer = L.canvas({
        padding: 0.5
    });
    var len = Translation.p_list.length;
    var i; //because strict mode is on for js
    for (i = 0; i < len; i++) {
        //get js dynamic variable name
        var dynamic = i;
        var dynamic = getLatLng(Translation.p_list[i][2],Translation.p_list[i][3]);
        //console.log(dynamic);
        var mycircleMarker = L.circleMarker(dynamic,{
            renderer: myrenderer,
            color: 'black',
            radius: 5,
            opacity: 1,
            fillOpacity: 0 
        }).addTo(mymap);
        //var lat = dynamic[0];
        //var lng = dynamic[1];
        mycircleMarker.bindPopup(
            Translation.p_list[i][0].toString() 
            + ' - ' + 
            Translation.p_list[i][1].toString()
        );
        mycircleMarker.on('mouseover', function (e) {
            this.openPopup();
        });
        mycircleMarker.on('mouseout', function (e) {
            this.closePopup();
        });
    }//for loop end
    //end of canvas circlemarker

}//endof draw circle markers
function getPoints() {
    var list = [];
    var i;
    var len = Translation.p_list.length;

    for (i = 0; i < len; i++) {
        var latlng = getLatLng(Translation.p_list[i][2],Translation.p_list[i][3]);
        list.push(latlng);
    }
    return list;
}

function getLatLng(easting,northing){
    var osgb = new GT_OSGB();
    osgb.setGridCoordinates(easting, northing);
    var wgs84 = osgb.getWGS84();
    return [wgs84.latitude,wgs84.longitude];
}

function make_map2() {
    var map = L.map('map').setView([56, -3.28125], 7);
    L.tileLayer('https://maps.tilehosting.com/styles/streets/{z}/{x}/{y}.png?key=VrAxS62LrjdAZ0RDGRrK', {
        attribution: '<a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
        crossOrigin: true
    }).addTo(map);

    var len = Translation.p_list.length;
    var i; //because strict mode is on for js
    /*
    for (i = 0; i < len; i++) {
        var dynamic = i;
        var osgb = new GT_OSGB();
        osgb.setGridCoordinates(Translation.p_list[i][2], Translation.p_list[i][3]);
        var wgs84 = osgb.getWGS84();
    }
    */
    var pointList = getPoints();
    var polyline = new L.Polyline(pointList, {
        color: 'red',
        weight: 6,
        opacity: 0.9,
        smoothFactor: 1
    });
    map.addLayer(polyline);
    map.fitBounds(polyline.getBounds());
} //make_map2 end
export var App = {
    run: make_map,
    run2: make_map2
}
